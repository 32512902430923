
    /* eslint-disable */
    /* tslint:disable */
    
    // generated by react-imported-component, DO NOT EDIT     
    import {assignImportedComponents} from 'react-imported-component/macro';    
    
    // all your imports are defined here
    // all, even the ones you tried to hide in comments (that's the cost of making a very fast parser)
    // to remove any import from here
    // 1) use magic comment `import(/* client-side */ './myFile')` - and it will disappear
    // 2) use file filter to ignore specific locations (refer to the README)
    
    const applicationImports = assignImportedComponents([
           [() => import('./components/BlogAuthor/img/cassandra.jpg'), '', './src/components/BlogAuthor/img/cassandra.jpg', false],
      [() => import('./components/BlogAuthor/img/peter.jpg'), '', './src/components/BlogAuthor/img/peter.jpg', false],
      [() => import('./components/logos/img/logo-brown.svg'), '', './src/components/logos/img/logo-brown.svg', false],
      [() => import('./components/logos/img/symbol-brown.svg'), '', './src/components/logos/img/symbol-brown.svg', false],
      [() => import('./components/logos/img/symbol-color.svg'), '', './src/components/logos/img/symbol-color.svg', false],
      [() => import('./components/logos/img/wordmark-blue.svg'), '', './src/components/logos/img/wordmark-blue.svg', false],
      [() => import('./components/logos/img/wordmark-brown.svg'), '', './src/components/logos/img/wordmark-brown.svg', false],
      [() => import('./essays/AtScale/img/atscale.jpg'), '', './src/essays/AtScale/img/atscale.jpg', false],
      [() => import('./essays/AtScale/index.js'), '', './src/essays/AtScale/index.js', false],
      [() => import('./essays/Deficiencies/img/deficiencies.jpg'), '', './src/essays/Deficiencies/img/deficiencies.jpg', false],
      [() => import('./essays/Deficiencies/index.js'), '', './src/essays/Deficiencies/index.js', false],
      [() => import('./essays/DreamTeam/img/dreamteam.jpg'), '', './src/essays/DreamTeam/img/dreamteam.jpg', false],
      [() => import('./essays/DreamTeam/index.js'), '', './src/essays/DreamTeam/index.js', false],
      [() => import('./essays/Punched/img/punched.jpg'), '', './src/essays/Punched/img/punched.jpg', false],
      [() => import('./essays/Punched/index.js'), '', './src/essays/Punched/index.js', false],
      [() => import('./essays/StayTrue/img/staytrue.jpg'), '', './src/essays/StayTrue/img/staytrue.jpg', false],
      [() => import('./essays/StayTrue/index.js'), '', './src/essays/StayTrue/index.js', false],
      [() => import('./essays/WellRootedStartup/img/wellrooted.jpg'), '', './src/essays/WellRootedStartup/img/wellrooted.jpg', false],
      [() => import('./essays/WellRootedStartup/index.js'), '', './src/essays/WellRootedStartup/index.js', false],
      [() => import('./pages/Blog/img/banner.svg'), '', './src/pages/Blog/img/banner.svg', false],
      [() => import('components/EmailTest'), '', 'components/EmailTest', false],
      [() => import('components/Logout'), '', 'components/Logout', false],
      [() => import('library/layouts/ErrorPage'), '', 'library/layouts/ErrorPage', false],
      [() => import('library/loading/Loading'), '', 'library/loading/Loading', false],
      [() => import('pages/Blog'), '', 'pages/Blog', false],
      [() => import('pages/ForgotPw'), '', 'pages/ForgotPw', false],
      [() => import('pages/Index'), '', 'pages/Index', false],
      [() => import('pages/Login'), '', 'pages/Login', false],
      [() => import('pages/SetPw'), '', 'pages/SetPw', false],
      [() => import('pages/Signup'), '', 'pages/Signup', false],
    ]);
    
    export default applicationImports;
    
    // @ts-ignore
    if (module.hot) {
       // these imports would make this module a parent for the imported modules.
       // but this is just a helper - so ignore(and accept!) all updates
       
       // @ts-ignore
       module.hot.accept(() => null);
    }    
    