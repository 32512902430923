import store from 'elements/store/localStorage'

// from https://github.com/monperrus/crawler-user-agents
const botPattern =
  '(googlebot/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis|HeadlessChrome)'

export const testBot = (userAgent = '') => {
  if (userAgent === 'ReactSnap') return true
  const re = new RegExp(botPattern, 'i')
  return re.test(userAgent)
}

const getDevice = userAgent => {
  const agent = (userAgent || '').toLowerCase()
  if (~agent.indexOf('ipad')) return 'iPad'
  if (~agent.indexOf('iphone')) return 'iPhone'
  if (~agent.indexOf('android')) return 'Android'
  if (~agent.indexOf('mac os')) return 'Mac'
  if (~agent.indexOf('windows')) return 'Windows'
  return null
}

const ALPHABET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'

const ID_LENGTH = 4
const genId = seed => {
  let id = seed
  for (let i = 0; i < ID_LENGTH; i++) {
    id += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
  }

  return id
}

const getSession = () => {
  // grab the session values to store
  const ref = document.referrer
  const { userAgent, language } = window.navigator
  // solid browser support now
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const set = {
    app: 'w',
    meta: {},
  }
  if (ref) set.ref = ref
  if (userAgent) set.meta.device = getDevice(userAgent)
  if (language) set.meta.language = language
  if (timezone) set.meta.timezone = timezone
  // get performance
  if (!window.performance) return set
  const { loadEventEnd, responseEnd, fetchStart } = window.performance.timing

  if (responseEnd && fetchStart && responseEnd - fetchStart >= 0)
    set.meta.latency = responseEnd - fetchStart
  if (responseEnd && loadEventEnd && loadEventEnd - responseEnd >= 0)
    set.meta.pageLoad = loadEventEnd - responseEnd

  set.deviceId = store.get('deviceId')
  if (!set.deviceId) {
    set.deviceId = genId(new Date().getTime())
    store.set('deviceId', set.deviceId)
  }
  return set
}

export default getSession

export const finalCall = ({ url, data, latency = 0 }) => {
  const { vendor, userAgent } = window.navigator
  // Note: https://bugs.webkit.org/show_bug.cgi?id=188329 Safari bug is fixed but not yet released. When that happens, will need to parse userAgent for safari version also
  if (window.navigator.sendBeacon && !~vendor.indexOf('Apple')) {
    // try to send the beacon
    const beacon = window.navigator.sendBeacon(url, data)
    // it worked, exit here
    if (beacon) return
    // if it's blocked by adblocker, try the other way
  }
  // use async with desktop
  const async = !(~userAgent.indexOf('iPad') || ~userAgent.indexOf('iPhone'))

  const request = new XMLHttpRequest()
  request.open('POST', url, async) // `false` makes the request synchronous
  request.setRequestHeader('Content-Type', 'application/json')
  request.send(data)

  // Chrome doesn't care about waiting
  if (!async || ~vendor.indexOf('Google')) return

  const t = Date.now() + Math.max(300, latency + 200)
  while (Date.now() < t) {
    // postpone the JS loop for 300ms so that the request can complete
    // a hack necessary for Firefox and Safari refresh / back button
  }
}
