import { useMutation } from '@apollo/client'
import gql from 'graphql-tag.macro'

// convert to resolver
const mutation = gql`
  mutation InsertEvent($objects: [events_insert_input!]!) {
    insert_events(objects: $objects) {
      returning {
        sessionId
      }
    }
  }
`

const useInsertEvent = options => {
  const [insertEvents] = useMutation(mutation, {
    ...options,
    context: { route: 'public' },
  })
  return variables => insertEvents({ variables })
}

export default useInsertEvent
