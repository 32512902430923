import { meta as stayTrue } from './StayTrue/meta'
import { meta as wellRooted } from './WellRootedStartup/meta'
import { meta as atScale } from './AtScale/meta'
import { meta as dreamTeam } from './DreamTeam/meta'
import { meta as punched } from './Punched/meta'
import { meta as deficiencies } from './Deficiencies/meta'
// Order matters
const prod = { deficiencies, punched, dreamTeam, atScale, wellRooted, stayTrue }
const dev = {}

export const essays = {
  ...(process.env.NODE_ENV === 'development' ? dev : {}),
  ...prod,
}

export const rss = () => essays
