import React from 'react'

import { Switch, Route, Redirect } from 'react-router-dom'

import Load from 'library/react/Load'
import ScrollToTop from 'library/ui/ScrollToTop'

// NOTE: Make sure to only ever load directly from elements.
// Don't load elements/index.js - will break web
import Page from 'elements/analytics/Page'
import 'react-imported-component/macro'

import { essays } from 'essays'

const devRoutes =
  process.env.NODE_ENV === 'development' ? (
    <Route
      path="/emailtest"
      component={Load(() => import('components/EmailTest'))}
      exact
    />
  ) : null

const Home = Load(() => import('pages/Index'))
const Blog = Load(() => import('pages/Blog'))
const Login = Load(() => import('pages/Login'))
const ForgotPw = Load(() => import('pages/ForgotPw'))
const SetPw = Load(() => import('pages/SetPw'))
const Signup = Load(() => import('pages/Signup'))
const Logout = Load(() => import('components/Logout'))

// from blog
const essayRoutes = Object.keys(essays).map(k => (
  <Route
    key={k}
    component={Load(essays[k].load)}
    path={'/' + essays[k].path}
    exact
  />
))

const Routes = () => {
  return (
    <>
      <Route component={ScrollToTop} />
      <Switch>
        {essayRoutes}

        <Route path="/signup" component={Signup} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/logout" component={Logout} exact />
        <Route path="/forgot-password" component={ForgotPw} exact />
        <Route path="/set-password" component={SetPw} exact />
        <Route path="/smokesignals" component={Blog} exact />
        <Route path="/" component={Home} exact />
        <Route path="/r/:code" component={Home} exact />
        <Route path="/logged-out" render={() => <Home loggedOut />} exact />
        {devRoutes}
        <Redirect to="/" />
      </Switch>
      <Route component={Page} />
    </>
  )
}

export default Routes
