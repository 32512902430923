import { useContext } from 'react'
import Context from './Context'
import useEvent from './useEvent'

const Page = ({ location }) => {
  // don't fire first page view until session starts
  const started = !!useContext(Context).data
  useEvent(
    e => {
      // Don't track app page views (For Now)
      if (location.pathname.indexOf('/app') === 0) return
      if (started)
        e(location.pathname, {
          label: 'PAGE',
        })
    },
    [location.pathname, started]
  )

  // const event = useEvent()
  // // const prevPath = usePrevious(location.pathname)
  // console.log('event')
  // useEffect(() => {
  //   let called
  //   if (started && !called) {
  //     console.log(location.pathname)
  //     event(location.pathname, {
  //       label: 'PAGE',
  //     })
  //     called = true
  //   }
  // }, [location.pathname, started, event])
  return null
}

export default Page
