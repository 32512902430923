import { useRef } from 'react'
// import useEvent from './useEvent'
import useEventListener from 'elements/react/useEventListener'

const userAgent = window.navigator.userAgent
const iOS = ~userAgent.indexOf('iPad') || ~userAgent.indexOf('iPhone')

const Exit = () => {
  const skip = useRef()
  // const event = useEvent()
  const exitSite = () => {
    if (skip.current) return
    skip.current = true
    // event('exit site', { label: 'EXIT' })
  }

  // register function to be called on end of session
  useEventListener('pagehide', exitSite)
  useEventListener('beforeunload', exitSite)
  useEventListener('unload', exitSite)
  useEventListener('blur', () => (iOS ? exitSite : {}))

  return null
}

export default Exit
