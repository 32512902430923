import 'react-imported-component/macro'
import metaImage from './img/wellrooted-meta.jpg'
export const meta = {
  title: 'The Well-Rooted Cannabis Startup',
  description:
    'The most important elements of growing your business exist beneath the surface',
  img: () => import('./img/wellrooted.jpg'),
  load: () => import('./index.js'),
  path: 'well-rooted',
  by: 'cassandra',
  metaImage,
}
