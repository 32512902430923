import PropTypes from 'prop-types'

export const pt = {
  ...PropTypes,
  // define custom types here
  string: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
  ]),
  styles: PropTypes.object, // of type style
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  source: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
  ]),
  id: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  spacer: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
}
