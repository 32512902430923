import React from 'react'
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/link-error'
import { WebSocketLink } from '@apollo/link-ws'
import { pt } from 'elements/types/propTypes'
import store from 'elements/store/localStorage'
import { RestLink } from 'apollo-link-rest'
import handleErrors from './handleErrors'
import { useAuth } from 'api/auth/AuthContext'
import loggerLink from './loggerLink'

const dev =
  process.env.NODE_ENV !== 'production' ||
  store.get('myCode') === process.env.REACT_APP_MY_CODE
// offline
//https://github.com/larkintuckerllc/ApolloReactOffline/blob/fe131ff684afdad715a007194c1d3fb574d48b6d/src/components/App/index.tsx

const cache = new InMemoryCache()

const errorLink = options =>
  onError(error => {
    handleErrors({ error, options })
  })

// TODO: ensure same-origin works for sending cookie across subdomains
const credentials = dev ? 'include' : 'same-origin'

// // not really used yet - will for offline
// const stateLink = withClientState({
//   cache,
//   resolvers,
// })

// TODO?: GZIP https://docs.hasura.io/1.0/graphql/manual/deployment/compression.html
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  credentials,
})

// https://www.apollographql.com/docs/link/links/rest/
const restLink = new RestLink({
  uri: process.env.REACT_APP_AUTH_URL,
  credentials,
})

const wsLink = options =>
  new WebSocketLink({
    uri: process.env.REACT_APP_API_URL.replace(/^https?/, dev ? 'ws' : 'wss'),
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: () => {
        return { headers: { ...options.headers } }
      },
    },
  })

const contextLink = options =>
  new ApolloLink((operation, forward) => {
    const { route } = operation.getContext()
    if (route !== 'public' && route !== 'init') {
      // skip execution if headers not present
      if (!options.headers['x-hasura-profile-id']) {
        // TODO: better UX? Sometimes its an error, sometimes, it's just waiting until onbaording is complete
        console.log('Operation executation skipped.')
      }
    }

    operation.setContext(({ headers }) => {
      return {
        headers: {
          ...headers,
          // changes permissions in hasura for this query (which sets profileId and accountId)

          route: route || 'protected',
          ...options.headers,
        },
      }
    })

    return forward(operation)
  })

const terminatingLink = options =>
  ApolloLink.split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink(options),
    httpLink
  )

const links = options => [
  errorLink(options),
  contextLink(options),
  // stateLink,
  restLink,
  terminatingLink(options),
]

// setup your client
const client = options => {
  const l = links(options)
  // TODO: new logger link with apollo v3
  if (dev) l.unshift(loggerLink)

  return new ApolloClient({
    cache,
    link: ApolloLink.from(l),
  })
}

// TODO: remove history hook from parent
// using history.push results in infinite rerenders
// instead, am using winodw.location
// when hasura returns headers from authook, could use server redirects

const Apollo = ({ children }) => {
  // get context for headers
  const context = useAuth()

  // passed into links
  const options = {
    headers: {},
  }
  // set context headers for hasura
  if (context.account?.id)
    options.headers['x-hasura-account-id'] = context.account.id
  if (context.profile?.id)
    options.headers['x-hasura-profile-id'] = context.profile.id

  // pass history into links
  // if (history) {
  //   options.push = history.push
  //   options.path = history.location.pathname
  //   if (
  //     process.env.REACT_APP_APP_URL &&
  //     options.path.indexOf(process.env.REACT_APP_APP_URL) === 0
  //   )
  //     options.isApp = true
  // }

  return <ApolloProvider client={client(options)}>{children}</ApolloProvider>
}

Apollo.propTypes = {
  children: pt.children,
}

export default Apollo
