import 'react-imported-component/macro'
import metaImage from './img/dreamteam-meta.jpg'
export const meta = {
  title: 'Discovering the Dream Team You Didn’t Know You (Already) Have',
  description:
    'How to inspire communication, loyalty, and innovation after your team has polarized',
  img: () => import('./img/dreamteam.jpg'),
  load: () => import('./index.js'),
  path: 'discovering-the-dream-team',
  by: 'cassandra',
  metaImage,
}
