import 'react-imported-component/macro'

import metaImage from './img/punched-meta.jpg'
export const meta = {
  title: 'An App for When You Get Punched in the Face',
  description: "Or rather, why doesn't it exist yet?",
  img: () => import('./img/punched.jpg'),
  load: () => import('./index.js'),
  path: 'when-you-get-punched-in-the-face',
  by: 'pcmaffey',
  metaImage,
}
