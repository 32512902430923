export const json = {
  parse: str => {
    if (!str) return null
    if (typeof str !== 'string') return str
    try {
      return JSON.parse(str)
    } catch (e) {
      console.log('Invalid JSON to parse', str)
      return {}
    }
  },
  str: (obj = {}) => (typeof obj === 'object' ? JSON.stringify(obj) : obj),
}
