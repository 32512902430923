import { useEffect } from 'react'
import PropTypes from 'prop-types'

const ScrollToTop = ({ root = 'root', location }) => {
  useEffect(() => {
    const container = root ? document.getElementById(root) : window
    container.scrollTo(0, 0)
  }, [location.pathname, root])
  return null
}

ScrollToTop.propTypes = {
  location: PropTypes.object,
  root: PropTypes.string,
}

export default ScrollToTop
