import Async from 'react-imported-component'
import 'react-imported-component/macro'

const Load = component => {
  if (!component) return
  return Async(component, {
    LoadingComponent: Async(() => import('library/loading/Loading')),
    ErrorComponent: Async(() => import('library/layouts/ErrorPage')),
  })
}

export default Load
