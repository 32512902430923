import 'react-imported-component/macro'
import metaImage from './img/atscale-meta.jpg'
export const meta = {
  title: 'High Quality Cannabis At Scale',
  description:
    'Simple management and design choices that can help your team grow award-winning cannabis at scale',
  img: () => import('./img/atscale.jpg'),
  load: () => import('./index.js'),
  path: 'quality-cannabis-at-scale',
  by: 'cassandra',
  metaImage,
}
