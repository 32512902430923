import React from 'react'
import { BrowserRouter, useHistory } from 'react-router-dom'

import Routes from '../Routes'
import AnalyticsProvider from 'elements/analytics/Provider'
import Apollo from 'api/Apollo'
import AuthProvider from 'api/auth/AuthContext'
import './styles.css'
const Stem = () => {
  const history = useHistory()
  return (
    <AuthProvider>
      <Apollo history={history}>
        <AnalyticsProvider>
          <Routes />
        </AnalyticsProvider>
      </Apollo>
    </AuthProvider>
  )
}

const Root = () => {
  return (
    <BrowserRouter>
      <Stem />
    </BrowserRouter>
  )
}
export default Root
