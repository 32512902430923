import { json } from 'helpers/json'
const storage = window.localStorage || null

const store = {
  get: storage
    ? (k, isObj) => {
        const v = storage.getItem(k)
        return isObj ? json.parse(v) : v
      }
    : () => null,
  set: storage
    ? (k, v, isObj) => {
        storage.setItem(k, isObj ? json.str(v) : v)
      }
    : () => null,
  remove: storage ? k => storage.removeItem(k) : () => null,
}

export default store
