import 'react-imported-component/macro'
import metaImage from './img/staytrue-meta.jpg'
export const meta = {
  title: 'Stay True to Yourself in the Hustle',
  description:
    'How to leverage your personal values to achieve lasting success in the cannabis industry',
  img: () => import('./img/staytrue.jpg'),
  load: () => import('./index.js'),
  path: 'stay-true',
  by: 'cassandra',
  metaImage,
}
