import { useContext, useEffect, useCallback } from 'react'
import useInsertEvent from 'api/analytics/useInsertEvent'
import store from 'elements/store/localStorage'
import SessionContext from './Context'

const myCode = store.get('myCode')
const skip = myCode && myCode === process.env.REACT_APP_MY_CODE
const dev = process.env.NODE_ENV === 'development' || skip

// register the Event function with vars defined
const register = ({ sessionId, isBot, insertEvent, data }) => (
  name,
  properties = {}
) => {
  if (isBot) return
  const timestamp = new Date().getTime()

  const { label, value, ...rest } = properties
  const apiEvent = {
    event: name,
    label,
    value,
  }
  const lastActive = store.get('lastActive')

  // NOTE - defining a session as 8hrs since last event
  // check for old string sessionId
  if (!isNaN(sessionId) && lastActive && timestamp - lastActive < 28800000)
    apiEvent.sessionId = sessionId
  else {
    apiEvent.session = {
      data,
    }
  }
  if (!skip) insertEvent({ objects: [apiEvent] })

  store.set('lastActive', timestamp)

  if (name === 'exit site') {
    let latency = 0
    if (data && data.meta.latency) latency = data.meta.latency
    const t = Date.now() + Math.max(300, latency + 200)
    while (Date.now() < t) {
      // postpone the JS loop for 300ms so that the request can complete
      // a hack necessary for Firefox and Safari refresh / back button
    }
  }
  // // log to console
  if (dev) {
    console.log(label + ': ' + name, JSON.stringify({ value, ...rest }))
  }
}
// Hook
// can either pass in an event function with array of dependencies to be called in a useEffect
// else will return an event function to be called
const useEvent = (event, deps = []) => {
  const { sessionId, data, isBot, setSessionId } = useContext(SessionContext)

  // register mutation
  const insertEvent = useInsertEvent({
    onCompleted: ({ insert_events }) => {
      setSessionId(insert_events.returning[0].sessionId)
    },
    onError: error => {
      if (~error.message.indexOf('events_session_id_fkey')) {
        setSessionId()
      }
    },
  })
  /* eslint-disable react-hooks/exhaustive-deps */
  // specifically not including functions here
  const e = useCallback(register({ sessionId, insertEvent, isBot, data }), [
    sessionId,
    data,
    isBot,
  ])

  // passing in deps this way to avoid referencing e function as a dep, which will cause infinite loop
  useEffect(() => {
    if (event) {
      event(e)
    }
  }, deps)

  return !event ? e : null
}

export default useEvent
