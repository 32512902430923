const handleErrors = ({ error, options }) => {
  const { graphQLErrors } = error
  if (graphQLErrors) {
    graphQLErrors.forEach(e => {
      const code = (e.extensions || {}).code
      // unauthorized
      // TODO: this runs through web logout route. Need to make self contained
      if (code === 'access-denied') {
        if (
          window.location.pathname.indexOf(process.env.REACT_APP_APP_URL) ===
            0 &&
          !~window.location.pathname.indexOf('/logout')
        )
          window.location = window.location =
            process.env.REACT_APP_WEB_URL + '/logout?auto&from=access-denied'
      }
    })
  }
}

export default handleErrors
