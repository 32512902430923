import 'react-imported-component/macro'
import metaImage from './img/deficiencies-meta.jpg'
export const meta = {
  title: 'Psuedo-Nutrient Deficiencies in Cannabis',
  description:
    'Troubleshooting the most common and elusive nutrient deficiency',
  img: () => import('./img/deficiencies.jpg'),
  load: () => import('./index.js'),
  path: 'pseudo-nutrient-deficiencies-in-cannabis',
  by: 'cassandra',
  metaImage,
}
