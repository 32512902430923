import React, { useState } from 'react'
import store from 'elements/store/localStorage'
import useEventListener from 'elements/react/useEventListener'
import getSession, { testBot } from './session'
import SessionContext from './Context'
import Exit from './Exit'
const isBot = testBot(window.navigator.userAgent)

// NOTE - defining a session as 8hrs since last event
const Provider = ({ children }) => {
  const [sessionId, setSessionId] = useState(store.get('sessionId'))
  const [data, setData] = useState()

  // start listening for page load - will only log events after load
  useEventListener('pageshow', () => setData(getSession()))

  return (
    <SessionContext.Provider
      value={{
        data,
        isBot,
        sessionId,
        setSessionId: id => {
          setSessionId(id)
          if (id) store.set('sessionId', id)
          else {
            store.remove('sessionId')
            store.remove('lastActive')
          }
        },
      }}>
      <>
        {children}
        {data && <Exit />}
      </>
    </SessionContext.Provider>
  )
}

export default Provider
