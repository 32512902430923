import 'regenerator-runtime/runtime' // necessary for async/await

import React from 'react'

import { render } from 'react-dom'

import Root from 'components/Root'
// import { AppRegistry } from 'react-native'

/* eslint-disable no-unused-vars */
import importedComponents from 'imported'
import { rehydrateMarks, drainHydrateMarks } from 'react-imported-component'

const rootTag = document.getElementById('root')

// https://github.com/theKashey/react-imported-component/tree/master/examples/hybrid/react-snap
// wait for all the used marks to load
rehydrateMarks(window.__IMPORTED_COMPONENTS__).then(() => {
  render(<Root />, rootTag)
  // TODO: possible to use this instead with snap?
  // AppRegistry.registerComponent('Web', () => Root)
  // AppRegistry.runApplication('Web', {
  //   callback: () => {
  //     console.log('React rendering has finished')
  //   },
  //   hydrate: true,
  //   initialProps: {},
  //   rootTag,
  // })
})

// save used marks
window.snapSaveState = () => ({
  __IMPORTED_COMPONENTS__: drainHydrateMarks(),
})
