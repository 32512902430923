import React, { useContext, useReducer } from 'react'
import { pt } from 'elements/types/propTypes'

const Context = React.createContext({})

export const useAuth = () => {
  return useContext(Context)
}

const reducer = (state, dispatch) => {
  if (dispatch === 'reset') return {}
  return { ...state, ...dispatch }
}

const ContextProvider = ({ children, defaults = {} }) => {
  const [state, set] = useReducer(reducer, defaults)
  const context = {
    ...state,
    set,
  }
  return <Context.Provider value={context}>{children}</Context.Provider>
}

ContextProvider.propTypes = {
  children: pt.children,
  defaults: pt.object,
}

export default ContextProvider
